module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JasperMW-Portfolio","short_name":"JasperMW","start_url":"/","background_color":"#3a5455","theme_color":"#f5f5f5","display":"standalone","icon":"src/assets/image/FallstopLogo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"758f51b08b741e9369cf133ad92e72a6"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"backgroundColor":"transparent","withWebp":true,"withAvif":true,"disableBgImageOnAlpha":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
